import styled from "styled-components";

export const LandingTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  padding: 60px 0px 0px 0px;
  position: relative;

  .smartls-text-container {
    position: relative;
    height: 20vh;
    .smartls-text {
      opacity: 0;
    }
    .bb-text {
      opacity: 0;
      position: absolute;
      top: 6px;
    }
  }

  .canvas {
    scale: 4;
    width: 46vh;
    margin-left: -8vw;
    position: absolute;
    z-index: 1;
    @media screen and (min-width: 520px) and (max-width: 1060px) {
      width: 50vh;
      margin-left: 6vw;
      margin-top: 3vh;
    }
  }
`;

export const RedBackground = styled.div`
  position: absolute;
  top: 9vh;
  left: 8vw;
  width: 78vw;
  height: 68vh;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(247, 13, 64, 0.5) 21.35%,
    rgba(0, 0, 0, 0) 100%
  );
  transform: rotate(1.46deg);
  opacity: 0.7;
  z-index: 1;
`;

export const TextContainer = styled.div`
  width: 248px;
  max-height: 190px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  /* top: 174vh; */
  /* display: none; */
  opacity: 0;
  z-index: 2;
  overflow: hidden;
  margin-top: 24px;

  @media screen and (min-width: 520px) and (max-width: 1060px) {
    width: 62vw;
    max-height: 260px;
    margin-top: 8vh;
  }

  .landing-tilefirst-section-container {
    display: flex;
    position: relative;
    height: 42px;
    overflow: hidden;
    @media screen and (min-width: 520px) and (max-width: 1060px) {
      height: 60px;
    }
  }

  .small-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: 0.03em;
    color: #ffffff;
    position: absolute;
    top: 6px;
    @media screen and (min-width: 520px) and (max-width: 1060px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .first-heading {
    display: flex;
    flex-wrap: wrap;
  }

  .logo {
    position: absolute;
    top: 0;
    left: 0;
    img {
      height: 36px;
      @media screen and (min-width: 520px) and (max-width: 1060px) {
        height: 44px;
      }
    }
  }

  .last-heading {
    display: flex;
    flex-wrap: wrap;
    width: 220px;
    height: 240px;
    height: auto;
  }

  .text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.04em;
    color: #ffffff;
    @media screen and (min-width: 520px) and (max-width: 1060px) {
      font-size: 44px;
      line-height: normal;
    }
  }

  svg {
    min-width: 112px;
    min-height: 40px;
  }
`;

/* eslint-disable @next/next/no-img-element */
import { LandingTileContainer, TextContainer } from "./styles-v3";
import React from "react";
import gsap from "gsap";
import { useRef } from "react";
import anime from "animejs";
const shortid = require("shortid");
import { BackgroundGlow } from "../../common/background-glow";
import { useIsomorphicLayoutEffect } from "../../../pages";
import { getCookie } from "../../../utils/cookies";
import { useRouter } from "next/router";
import { default as NextImage } from "next/image";

interface LandingTileMobileProps {
  data: Data;
}

interface Data {
  imagesDesktopBasePath: string;
  imagesMobileBasePath: string;
  startIndex: number;
  endIndex: number;
  firstLineLogo: string;
  firstLineText: string;
  secondLineText: string;
  thirdLineText: string; // This should chnage to thirdLineText after CMS update
}

export const LandingTile = (props: LandingTileMobileProps) => {
  const router = useRouter();
  const animeRefFour = useRef<any>(null);
  const animeTextAnimationRef = useRef<any>(null);
  let initialFlag = false;
  let rotateTweenFlag = false;
  let imgsLoaded = 0;
  const frameCount = Math.floor(props.data.endIndex / 2);

  const currentFrame = (index: number | string) => {
    let idx = index + "";
    if (idx.length === 1) {
      idx = "000" + idx;
    } else if (idx.length === 2) {
      idx = "00" + idx;
    } else if (idx.length === 3) {
      idx = "0" + idx;
    }
    return `${props.data.imagesMobileBasePath}3d_mobile${idx}.webp`;
  };

  const images: any[] = [];

  useIsomorphicLayoutEffect(() => {
    if (typeof window !== undefined) {
      anime({
        targets: ".m-main",
        opacity: 0,
        duration: 0,
      });

      anime({
        targets: ".logo",
        opacity: 0,
        width: "0px",
        duration: 0,
      });

      anime({
        targets: ".threed-red-bg",
        opacity: 0,
        duration: 0,
      });
      if (window.innerWidth > 520 && window.innerWidth <= 920) {
        gsap.to(".ls-text-container", {
          top: "68vh",
          // display: "block",
          opacity: 1,
          duration: 0,
        });
      } else {
        gsap.to(".ls-text-container", {
          top: "65vh",
          // display: "block",
          opacity: 1,
          duration: 0,
        });
      }
    }

    return () => {};
  }, []);

  useIsomorphicLayoutEffect(() => {
    /*
    To create a mobile rotation animation effect for the landing tile, 
    we employed a sequential image rendering method. By binding GSAP scroll 
    triggers with the sequential rendering of images, we achieve the desired animation. 
    As the user scrolls, we update the canvas with the next and previous images, 
    resulting in a smooth and dynamic rotation effect on mobile devices.
    
    In the landing tile animation, we execute three distinct steps:
        1) Zoom out the mobile device to the center.
        2) Move the mobile device from the center to the right.
        3) Simultaneously rotate the mobile device while animating the text.
    */

    const canvas: any = document.querySelector(".canvas");

    if (typeof window !== undefined) {
      if (window.innerHeight > 520 && window.innerHeight <= 1060) {
        canvas.width = 900;
      } else {
        canvas.width = 1100;
      }
    }
    canvas.height = 1.78 * canvas.width;

    const context = canvas.getContext("2d");

    let mobile = { frame: 1 };

    const matchMedia = gsap.matchMedia();
    const handleMedia = (mediaContext: any) => {
      const { isMobile } = mediaContext.conditions;
      if (isMobile) {
        let tween = gsap.to(mobile, {
          frame: frameCount - 3,
          snap: "frame",
          ease: "none",
          duration: 1,
          paused: true,
          onUpdate: () => {
            if (mobile.frame < Math.floor(props.data.endIndex / 4) - 2) {
              render();
            }
          },
          onComplete: () => {
            rotateTweenFlag = true;
          },
        });

        let pauseTween = gsap.to(".ls-text-container", {
          duration: 0.5,
          paused: true,
          onComplete: () => {
            console.log("pause tween play: ");
            tween.play();
          },
        });

        /*
          this tween has zoom out logic
        */
        let tween2 = gsap.to(".canvas", {
          scale: 0.95,
          duration: 0.75,
          paused: true,
          onStart: () => {
            // alert("yes");
          },
          onComplete: () => {
            /*
              We pause animation for sometime and start text animation.
              Till this point whole animation happens automatically (not scroll based).
            */
            pauseTween.play();
            handleTextAnimation();
          },
        });

        if (imgsLoaded > Math.floor(props.data.endIndex / 6) - 4) {
          /*
            We have incorporated this logic to handle scenarios where the user resizes the page, transitioning between
            the mobile and desktop views, and vice versa. This is no longer needed as we have seperate components
            for desktop and mobile. whole componnet rerenders on device chnage.We might need to remove this in future.
          */
          tween2.play();
        }

        const handleImageLoad = () => {
          /* We will initiate the animation only after ensuring that all the images have been loaded successfully. */
          imgsLoaded++;
          if (imgsLoaded > Math.floor(props.data.endIndex / 6) - 4) {
            if (!initialFlag) {
              gsap.to("#landing-tile-loader", {
                display: "none",
                duration: 0,
                onComplete: () => {
                  // context.clearRect(0, 0, canvas.width, canvas.height);
                  setTimeout(() => {
                    context.drawImage(images[0], 0, 0);

                    tween2.play();
                  }, 100);
                },
              });
              // setLoader(false);
              initialFlag = true;
            }
          }
        };

        for (let i = 1; i < props.data.endIndex; i = i + 4) {
          console.log("counting");
          const img = new Image();
          img.src = currentFrame(i);
          images.push(img);
          img.onload = handleImageLoad;
        }

        gsap.to(mobile, {
          frame: frameCount - 1,
          snap: "frame",
          ease: "none",
          paused: true,
          scrollTrigger: {
            trigger: ".landing-tile-container",
            scrub: true,
            pin: true,
            start: "top 1px",
            end: `+=${window.innerHeight}px`,
            pinSpacing: true,
          },
          onUpdate: () => {
            console.log("rotate tween play: " + mobile.frame);
            if (
              rotateTweenFlag &&
              mobile.frame < Math.floor(props.data.endIndex / 3) - 2
            ) {
              renderReverse();
            }
          },
        });

        //text zoom animation

        gsap.to(".ls-text-container", {
          scale: 1.5,
          opacity: 0,
          scrollTrigger: {
            trigger: ".landing-tile-container",
            start: "bottom 90%",
            end: "bottom 30%",
            scrub: true,
          },
        });
      } else {
        matchMedia.revert();
      }
    };
    matchMedia.add(
      {
        isMobile: `(max-width: 1060px)`,
        isDesktop: `(min-width: 1060px)`,
      },
      handleMedia
    );

    function render() {
      context?.clearRect(0, 0, canvas.width, canvas.height);
      context?.drawImage(images[mobile.frame], 0, 0);
    }

    function renderReverse() {
      let frameNo = Math.floor(props.data.endIndex / 4) - mobile.frame;
      if (frameNo >= 0) {
        context?.clearRect(0, 0, canvas.width, canvas.height);
        context?.drawImage(images[frameNo], 0, 0);
      }
    }
  }, []);

  const handleTextAnimation = () => {
    animeRefFour.current = anime({
      targets: ".threed-red-bg",
      opacity: [0, 0.6],
      scale: [0, 1],
      duration: 1000,
      easing: "easeOutExpo",
    });

    animeTextAnimationRef.current = anime
      .timeline({
        loop: false,
      })
      .add({
        targets: ".m-main .letter-lan",
        opacity: 0,
        duration: 10,
      })
      .add({
        targets: ".m-main",
        opacity: 1,
      })
      .add({
        targets: ".m-main .letter-lan",
        translateX: [40, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 600,
        delay: (el, i) => 300 + 20 * i,
      })
      .add({
        targets: ".small-text",
        translateX: [0, -60],
        translateZ: 0,
        opacity: [1, 0],
        easing: "easeOutExpo",
        duration: 600,
        delay: (el, i) => 300 + 20 * i,
      })
      .add({
        targets: ".logo",
        translateX: [40, 0],
        translateZ: 0,
        width: "auto",
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 400,
      });
    // if (
    //   getCookie("userRegion") == "in" &&
    //   (router.locale == "" || router.locale == "en")
    // ) {
    //   animeTextAnimationRef.current
    //     .add({
    //       targets: ".smartls-text",
    //       opacity: [1, 0],
    //       translateX: [0, -40],
    //       easing: "easeOutExpo",
    //     })
    //     .add({
    //       targets: ".bb-text",
    //       translateX: [40, 0],
    //       opacity: [0, 1],
    //       easing: "easeOutExpo",
    //     });
    // }
  };

  return (
    <LandingTileContainer className="landing-tile-container" id="zero">
      <div
        id="landing-tile-loader"
        style={{
          transform: "translate(-8vh,20vh)",
          width: "120px",
          zIndex: "99",
        }}
      >
        <NextImage
          src="/glance_loading.gif"
          alt=""
          width="250"
          height="250"
          loading="eager"
        ></NextImage>
      </div>
      {/* )} */}
      <TextContainer className="ls-text-container">
        <div className="landing-tilefirst-section-container">
          <div className="small-text first-heading m-main">
            <span className="letter-lan" key={shortid.generate()}>
              {props.data.firstLineText}
            </span>
          </div>
          <div className="logo">
            <img src={props.data.firstLineLogo} alt="glance logo" />
          </div>
        </div>
        <div className="smartls-text-container">
          <div className="text first-heading m-main smartls-text">
            <span className="letter-lan" key={shortid.generate()}>
              {props.data.secondLineText}
            </span>
          </div>
          <div className="text first-heading m-main smartls-text">
            <span className="letter-lan" key={shortid.generate()}>
              {props.data.thirdLineText}
            </span>
          </div>
          {/* <img
            className="bb-text"
            src={
              "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/india/yshb-single.png"
            }
            style={{ width: "59vw" }}
          /> */}
        </div>
      </TextContainer>

      <canvas className="canvas" id="hero-lightpass"></canvas>
      <BackgroundGlow
        background="radial-gradient(50% 50% at 50% 50%, rgba(160, 29, 146, 0.6) -43%, rgba(0, 0, 0, 0) 100%);"
        height="96vw"
        width="150vw"
        top="-30vh"
        left="-93vw"
        zIndex="0"
      />

      <BackgroundGlow
        background="radial-gradient(50% 50% at 50% 53%, rgba(247, 13, 64, 0.5) -26.35%, rgba(0, 0, 0, 0) 74%)"
        height="126vh"
        width="161vw"
        bottom="-5vh"
        left="-15vw"
        zIndex="0"
      />

      <BackgroundGlow
        background="radial-gradient(50% 50% at 50% 50%, #000000 0%, rgba(0, 0, 0, 0.53) 62.5%, rgba(0, 0, 0, 0) 100%);"
        height="68vh"
        width="260vw"
        bottom="0vh"
        left="-46vw"
        zIndex="0"
      />
    </LandingTileContainer>
  );
};

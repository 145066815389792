import styled from "styled-components";

interface CardProps {
  background?: string;
  zIndex: number;
  width?: string;
  height?: string;
  margin?: string;
}

export const PartnersContainer = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding-top: 60px;

  .letter-pt {
    opacity: 1;
    display: inline-block;
  }
`;

export const CardLogoWrapper = styled.div`
  width: 50vw;
  @media (max-width: 720px) {
    width: auto;
  }
`;

export const CardContainer = styled.div`
  width: 100vw;
  height: 62vh;
  position: relative;
  @media (max-width: 720px) {
    width: 100vw;
    height: 62vh;
  }
`;

export const Card = styled.div<CardProps>`
  position: absolute;
  width: 100vw;
  height: 59vh;
  /* display: none; */
  opacity: 0;
  z-index: ${(props) => props.zIndex};

  img {
    /* width: 60vw; */
    height: 59vh;
    margin-left: 4vw;
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  width: 28vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  height: 30px;
  margin-bottom: 40px;
  /* transform: translate(23vh, -10vh); */
  bottom: 36vh;
  left: 31.5vh;
`;

export const Logo = styled.img<CardProps>`
  position: absolute;
  width: ${(props) => props.width};
  /* margin-left: 12vw; */
  z-index: ${(props) => props.zIndex};
  /* display: none; */
  opacity: 0;
  margin: ${(props) => props.margin};
`;

export const TextContainer = styled.div`
  margin-left: 6vw;
  display: flex;
  flex-direction: column;
`;

export const LargeText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 46px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-bottom: 6px;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 60px;
    line-height: 66px;
  }
  /* .letter {
    opacity: 0;
  } */
`;

export const SmallText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  letter-spacing: 0.03em;
  color: #ffffff;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 34px;
    line-height: 43px;
  }
  /* .letter {
    opacity: 0;
  } */
`;

import gsap from "gsap";
import anime from "animejs";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
const shortid = require("shortid");
import { BackgroundGlow } from "../../common/background-glow";
import {
  BetterWithEveryGlanceContainer,
  ContentContainer,
  ContentSection,
  LockscreenCard,
  LockscreenCardContainer,
  MobileOutline,
  MobileSectionContainer,
  SmallText,
} from "./styles-v2";
import { useIsomorphicLayoutEffect } from "../../../pages";
import { MobileContent } from "../../common/common-styles";
import { LandingPageTextAnimation } from "../../common/landing-pages-text-animation";

interface BetterWithEveryGlanceProps {
  data: BwegCardData[];
}

interface BwegCardData {
  mobileLsUrl: string;
  desktopLsUrl: string;
}

export const BetterWithEveryGlanceV2 = (props: any) => {
  console.log("better props", props);
  useIsomorphicLayoutEffect(() => {
    const context = gsap.context(() => {
      const matchMedia = gsap.matchMedia();

      const handleMatchMedia = (mediaContext: any) => {
        const { isMobile } = mediaContext.conditions;
        if (isMobile) {
          ScrollTrigger.create({
            trigger: ".bweg-container",
            start: "top center",
            end: "+=50%",
            invalidateOnRefresh: true,
            onEnter: () => {
              anime.timeline({ loop: false }).add({
                targets: ".bweg-ta .letter-bweg",
                translateX: [40, 0],
                translateZ: 0,
                opacity: [0, 1],
                easing: "easeOutExpo",
                duration: 600,
                markers: true,
                delay: (el, i) => 350 + 10 * i,
              });
            },
          });
          gsap.set(".bweg-lockscreen-card-0", {
            opacity: 1,
            duration: 0,
          });
          let timeline = gsap.timeline({ paused: true });
          props.data.data.forEach((data: any, index: number) => {
            if (index != props.data.data.length - 1) {
              /*
                logic for fade in - fade out animation with diagonal animation
              */
              timeline
                .to(`.bweg-lockscreen-card-${index}`, {
                  /* x y chnages are responsible for diagonal animation */
                  x: 100,
                  y: -160,
                  opacity: 0,
                  duration: 1,
                })
                .to(`.bweg-lockscreen-card-${index + 1}`, {
                  opacity: 1,
                  duration: 0,
                  delay: -1,
                });
            }
          });

          ScrollTrigger.create({
            trigger: ".bweg-container",
            start: "top 1px",
            end: `+=${window.innerHeight * 2}px`,
            pin: true,
            pinSpacing: true,
            scrub: true,
            invalidateOnRefresh: true,
            animation: timeline,
          });
        } else {
          matchMedia.revert();
        }
      };

      matchMedia.add(
        {
          isMobile: `(max-width: 1060px)`,
          isDesktop: `(min-width: 1060px)`,
        },
        handleMatchMedia
      );
    });

    return () => context.revert();
  }, []);

  const generateMobileCards = () => {
    return props.data.data.map((data: any, index: number) => {
      return (
        <LockscreenCard
          src={data.mobileLsUrl}
          key={shortid.generate()}
          className={`bweg-lockscreen-card-${index}`}
          zIndex={props.data.data.length - index}
          loading="lazy"
          alt={data.mobileLsImageAltText}
        />
      );
    });
  };

  return (
    <BetterWithEveryGlanceContainer className="bweg-container" id="eight">
      <MobileSectionContainer>
        <LockscreenCardContainer>
          {generateMobileCards()}
        </LockscreenCardContainer>
        <MobileOutline src="https://web.glance-cdn.com/images/glance-website/corp/lockscreen/bweg-mobile-with-black-bg.png" />
      </MobileSectionContainer>

      <ContentContainer>
        <LandingPageTextAnimation
          firstLine={props.data.firstLineText}
          secondLine={props.data.secondLineText}
          thirdLine={""}
          textAnimationClassName={`bweg-ta`}
          textContainerClassName={`bweg-ta-container`}
          margin="0px"
          idMargin="-1vh 0vw 0vh 0vw"
        />
      </ContentContainer>

      <MobileContent>
        {/* adding background hue */}
        <BackgroundGlow
          background="radial-gradient(50% 50% at 50% 50%, rgba(160, 29, 146, 0.6) 0%, rgba(0, 0, 0, 0) 100%);"
          height="73vh"
          width="619px"
          bottom="26vh"
          left="-43vw"
        />
      </MobileContent>
    </BetterWithEveryGlanceContainer>
  );
};

import { useIsomorphicLayoutEffect } from "../../../pages";
import gsap from "gsap";
import anime from "animejs";
const shortid = require("shortid");
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import {
  AsSimpleAsGlanceV2Container,
  HandBackgroundImage,
  ContentSection,
  ImageContainer,
  LockscreenImage,
  SmallText,
  BackgroundImagesContainer,
  Backgroundimage,
  LargeText,
} from "./styles2";
import { LandingPageTextAnimation } from "../../common/landing-pages-text-animation";

interface AsSimpleProps {
  data: AsSimpleData[];
  fisrtLine: string;
  secondLine: string;
}

interface AsSimpleData {
  mobileBackground: string;
  desktopBackground: string;
  desktopLsImage: string;
  mobileLsImageAltText: string;
}

/* The animation logic in this component is identical to the one used in the Desktop As Simple section.
 To grasp the GSAP animations applied here, please consult the corresponding component, 
 as both sections utilize the same animation techniques.*/

export const AsSimpleAsGlanceV2 = (props: AsSimpleProps) => {
  useIsomorphicLayoutEffect(() => {
    const context = gsap.context(() => {
      const matchMedia = gsap.matchMedia();

      function handleMedia(mediaContext: any) {
        const { isDesktop, isMobile } = mediaContext.conditions;
        if (isMobile) {
          let backgroundImages: any[] = gsap.utils.toArray(".background-image");

          //Text animation
          ScrollTrigger.create({
            trigger: ".as-simple-container",
            start: "top center",
            end: `+=50%`,
            onEnter: () => {
              anime.timeline({ loop: false }).add({
                targets: ".letter-asta",
                translateX: [40, 0],
                translateZ: 0,
                opacity: [0, 1],
                easing: "easeOutExpo",
                duration: 600,
                delay: (el, i) => 350 + 10 * i,
              });
            },
          });

          let timeline = gsap.timeline({ paused: true });
          timeline.set(`.lockscreen-image-0`, {
            opacity: 1,
          });

          props.data.forEach((url, index) => {
            if (index != props.data.length - 1) {
              timeline
                .to(`.lockscreen-image-${index}`, {
                  opacity: 0,
                  duration: 1,
                })
                .to(`.lockscreen-image-${index + 1}`, {
                  opacity: 1,
                  duration: 1,
                  onStart: () => {
                    gsap.to(backgroundImages[index + 1], {
                      transform: `translateY(-${(index + 1) * 100}vh)`,
                      duration: 0.5,
                    });
                  },
                  onReverseComplete: () => {
                    gsap.to(backgroundImages[index + 1], {
                      transform: `translateY(-${index * 100}vh)`,
                      duration: 0.5,
                    });
                  },
                })
                .to(`.lockscreen-image-${index}`, {
                  duration: 2,
                });
            }
          });

          //For lockscreen card animation
          ScrollTrigger.create({
            trigger: ".as-simple-container",
            start: "top 1px",
            end: `${window.innerHeight * 3}px`,
            pin: true,
            pinType: "fixed",
            pinSpacing: true,
            scrub: true,
            animation: timeline,
          });
        } else {
          matchMedia.revert();
        }
      }

      matchMedia.add(
        {
          isMobile: `(max-width: 1060px)`,
          isDesktop: `(min-width: 1060px)`,
        },
        handleMedia
      );
    });

    return () => {
      context.revert();
    };
  }, []);

  const generateBackgrounds = () => {
    return props.data.map((data, index) => {
      return (
        <Backgroundimage
          key={index}
          className={"background-image"}
          background={data.desktopBackground}
        />
      );
    });
  };

  const generateCards = () => {
    return props.data.map((data, index) => {
      return (
        <LockscreenImage
          className={`lockscreen-image-${index}`}
          src={data.desktopLsImage}
          key={shortid.generate()}
          zIndex={props.data.length - index}
          loading="lazy"
          alt={data.mobileLsImageAltText}
        />
      );
    });
  };

  return (
    <AsSimpleAsGlanceV2Container className="as-simple-container" id="sixth">
      <BackgroundImagesContainer>
        {generateBackgrounds()}
      </BackgroundImagesContainer>
      <HandBackgroundImage src="https://web.glance-cdn.com/images/glance-website/corp/lockscreen/simple-hand.webp" />
      <ImageContainer>{generateCards()}</ImageContainer>
      <ContentSection>
        <LandingPageTextAnimation
          firstLine={props.fisrtLine}
          secondLine={props.secondLine}
          thirdLine={""}
          textAnimationClassName={`letter-asta`}
          textContainerClassName={`letter-asta-container`}
          margin="0px"
          idMargin="-2vh 0vw 0vh 0vw"
        />
      </ContentSection>
    </AsSimpleAsGlanceV2Container>
  );
};

import styled from "styled-components";

export const BetterWithEveryGlanceContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 54px 32px 32px 42px;
  position: relative;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 520px) {
    overflow: hidden;
    padding: 54px 32px 32px 42px;
    position: relative;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
  }

  @media screen and (min-width: 520px) and (max-width: 1060px) {
    overflow: hidden;
    padding: 10vh;
    position: relative;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
  }
`;

export const MobileSectionContainer = styled.div`
  width: 50vw;
  height: 680px;
  display: flex;
  position: relative;
  @media (max-width: 520px) {
    width: 100vw;
    height: 480px;
  }

  @media screen and (min-width: 520px) and (max-width: 1060px) {
    width: 100vw;
    height: 60vh;
  }
`;

export const MobileOutline = styled.img`
  position: absolute;
  height: 680px;
  justify-self: flex-end;
  right: 46px;

  @media (max-width: 520px) {
    height: 480px;
    left: 0px;
    right: auto;
  }

  @media screen and (min-width: 520px) and (max-width: 1060px) {
    height: 60vh;
    left: 0px;
    right: auto;
  }
`;

export const LockscreenCardContainer = styled.div`
  position: absolute;
  right: 375px;

  @media (max-width: 520px) {
    left: 0px;
  }

  @media screen and (min-width: 520px) and (max-width: 1060px) {
    left: 0px;
  }
`;

export const LockscreenCard = styled.img<any>`
  position: absolute;
  height: 636px;
  border-radius: 36px;
  left: 22px;
  top: 25px;
  opacity: 0;
  z-index: ${(props) => props.zIndex};
  @media (max-width: 520px) {
    height: 456px;
    left: 15px;
    top: 17px;
    border-radius: 26px;
  }

  @media screen and (min-width: 520px) and (max-width: 1060px) {
    height: 56vh;
    left: 2vh;
    top: 2.5vh;
    border-radius: 26px;
  }
`;

export const ContentContainer = styled.div`
  width: 50vw;
  margin-top: 16px;
  margin-left: 8px;
  @media (max-width: 520px) {
    width: 100vw;
    margin-top: 16px;
    margin-left: 8px;
  }

  @media screen and (min-width: 520px) and (max-width: 1060px) {
    width: 100vw;
    margin-top: 16px;
    margin-left: 8px;
  }
`;

export const ContentSection = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  font-size: 90px;
  line-height: 52px;

  @media (max-width: 1060px) {
    font-size: 46px;
    line-height: 46px;
  }

  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 60px;
    line-height: 66px;
  }
`;

export const SmallText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  letter-spacing: 0.03em;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;

  @media (max-width: 520px) {
    margin-top: 8px;
  }
  .hide-text-better-wid-glance {
    opacity: 0;
    transform: translateY(100%);
  }

  @media (max-width: 520px) {
    font-size: 27px;
    line-height: 34px;
  }

  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 34px;
    line-height: 43px;
  }
`;

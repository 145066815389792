import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { SeeHowButton } from "../../common/see-how-button/index-ls";
import { useIsomorphicLayoutEffect } from "../../../pages";
import { LandingPageTextAnimation } from "../../common/landing-pages-text-animation";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { useState } from "react";
gsap.registerPlugin(ScrollTrigger);

const Container = styled.div`
  width: 100vw;
  position: relative;
`;

const Image = styled.img`
  border-radius: 0rem 0rem 32px 32px;
  width: 100vw;
  height: 65vh;
  object-fit: cover;
`;
const CTASection = styled.div`
  height: 300px;
  margin-top: -35px;
  margin-left: 30px;
`;
const Title = styled.div`
  font-size: 46px;
  font-weight: 700;
  color: #fff;
  top: -10%;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 60px;
    line-height: 66px;
  }
`;

const Subtitle = styled.div`
  font-size: 25px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 1rem;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 34px;
    line-height: 43px;
  }
`;

interface OemDevicesProps {
  data: OemDevicesData;
}

interface OemDevicesData {
  firstLine: string;
  secondLine: string;
  thirdLine: string;
  mobileBackground: string;
  desktopBackground: string;
  desktopLsImage: string;
  navigateTo: string;
  mobileLsImageAltText: string;
}

export const OemDevices = (props: OemDevicesProps) => {
  const { t } = useTranslation("common");
  const [showText, setShowText] = useState(false);
  useIsomorphicLayoutEffect(() => {
    let matchMedia = gsap.matchMedia();

    function handleMedia(mediaContext: any) {
      const { isMobile } = mediaContext.conditions;
      if (isMobile) {
        ScrollTrigger.create({
          trigger: ".oem-devices-container",
          start: "top 1px",
          end: "+=300px",
          pinSpacing: true,
          invalidateOnRefresh: true,
          onEnter: () => {
            setShowText(true);
          },
        });
      } else {
        matchMedia.revert();
      }
    }

    matchMedia.add(
      {
        isMobile: `(max-width: 1060px)`,
        isDesktop: `(min-width: 1060px)`,
      },
      handleMedia
    );

    return () => {
      matchMedia.revert();
    };
  }, []);

  return (
    <Container className="oem-devices-container" id="thirteen">
      <Image
        src={props.data.mobileBackground}
        loading="lazy"
        alt={props.data?.mobileLsImageAltText}
      />
      <CTASection>
        {showText && (
          <LandingPageTextAnimation
            firstLine={props.data.firstLine}
            secondLine={props.data.secondLine}
            thirdLine={props.data.thirdLine}
            textAnimationClassName={"mobile-does-your-text-animation"}
            textContainerClassName={"mobile-does-yourtext-container"}
            margin="0px 0px 12px 0px"
            idMargin="6vh 0vw 2vh 0vw"
          />
        )}
        {/* <Link href={props.data.navigateTo} target="_blank"> */}
        <SeeHowButton
          marginText="0px 0px 0px 4px"
          text={t("explore")}
          margin="0px"
          width="109px"
          height="43px"
          fontSize="13px"
          background="linear-gradient(270deg, #000000 -77.99%, rgba(0, 0, 0, 0.17) 100%);"
          naviagteTo={props.data.navigateTo}
          target="_blank"
        />
        {/* </Link> */}
      </CTASection>
    </Container>
  );
};

/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
import gsap from "gsap";
import anime from "animejs";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useIsomorphicLayoutEffect } from "../../../pages";
import {
  Card,
  CardContainer,
  CardLogoWrapper,
  LargeText,
  Logo,
  LogoContainer,
  PartnersContainer,
  SmallText,
  TextContainer,
} from "./styles";
import { useRef, useState } from "react";
import { BackgroundGlow } from "../../common/background-glow";
import Image from "next/image";
import { LandingPageTextAnimation } from "../../common/landing-pages-text-animation";
gsap.registerPlugin(ScrollTrigger);

interface OemPartnersProps {
  data: OemPartnersData[];
  firstLine: string;
  secondLine: string;
  thirdLine: string;
  id?: string;
}

interface OemPartnersData {
  logo: string;
  mobileLogo: string;
  oemUrl: string;
  logoWidthDesktop: string;
  logoHeightDesktop: string;
  logoWidthMobile: string;
  logoHeightMobile: string;
  margin?: string;
  oemLogoAltText: string;
  oemImageAltText: string;
}

let oemBgUrls = [
  {
    oemUrl:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/partners/samsung.webp",
    logo: "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/partners/samsung-logo.svg",
    logoHeight: "32px",
    logoWidth: "132px",
  },
  {
    oemUrl:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/partners/realme.webp",
    logo: "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/partners/realme.svg",
    logoHeight: "25",
    logoWidth: "120px",
  },
  {
    oemUrl:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/partners/vivo.webp",
    logo: "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/partners/vivo-logo.svg",
    logoHeight: "25",
    logoWidth: "120px",
  },
  {
    oemUrl:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/partners/oppo.webp",
    logo: "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/partners/oppo-logo.svg",
    logoHeight: "25px",
    logoWidth: "120px",
  },
  {
    oemUrl:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/partners/mi.webp",
    logo: "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/partners/mi-logo.svg",
    logoHeight: "40px",
    logoWidth: "40px",
    margin: "0px 6px",
  },
  {
    oemUrl:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/partners/motorola.webp",
    logo: "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/partners/motorola-logo.svg",
    logoHeight: "40px",
    logoWidth: "40px",
    margin: "0px 6px",
  },
];

/* The animation logic in this component is identical to the one used in the Desktop OEM Partners section.
 To grasp the GSAP animations applied here, please consult the corresponding component, 
 as both sections utilize the same animation techniques.*/

export const Partners = (props: OemPartnersProps) => {
  const animeTimelineRef = useRef<any>(null);
  const [showText, setShowText] = useState(false);

  useIsomorphicLayoutEffect(() => {
    const matchMedia = gsap.matchMedia();

    function handleMedia(mediaContext: any) {
      const { isDesktop, isMobile } = mediaContext.conditions;
      if (isMobile) {
        let oemLogos: any[] = gsap.utils.toArray(".oem-partner-logos");
        let timeline: any;
        let scrollTrigger: any;
        let textAnimationScrollTrigger = ScrollTrigger.create({
          trigger: ".partners-container",
          start: "top center",
          end: "+=50%",
          invalidateOnRefresh: true,
          onEnter: () => {
            animeTimelineRef.current = anime.timeline({ loop: false }).add({
              targets: ".m112 .letter-pt",
              translateX: [40, 0],
              translateZ: 0,
              opacity: [0, 1],
              easing: "easeOutExpo",
              duration: 600,
              delay: (el, i) => 350 + 10 * i,
            });
          },
        });
        timeline = gsap.timeline({
          paused: true,
          repeat: -1,
        });
        timeline
          .set(`.oem-card-0`, { opacity: 1 })
          .set(oemLogos[0], { opacity: 1 });
        if (props.data.length > 1) {
          for (let i = 0; i < props.data.length; i++) {
            timeline
              .to(oemLogos[i + 1], {
                duration: 1,
              })
              .to(`.oem-card-${i}`, {
                opacity: 0,
                duration: 1,
              })

              .to(oemLogos[i], {
                opacity: 0,
                duration: 1,
                delay: -1,
              })
              .to(`.oem-card-${i + 1}`, {
                opacity: 1,
                duration: 1,
                delay: -1,
              })
              .to(oemLogos[i + 1], {
                opacity: 1,
                duration: 1,
                delay: -1,
              });
          }
        }

        scrollTrigger = ScrollTrigger.create({
          trigger: ".partners-container",
          start: "top 80%",
          end: `+=${window.innerHeight}px`,
          scrub: false,
          invalidateOnRefresh: true,
          animation: timeline,
          toggleActions: "play pause resume pause",
          onEnter: () => {
            setShowText(true);
          },
        });
      } else {
        matchMedia.revert();
      }
    }

    matchMedia.add(
      {
        isMobile: `(max-width: 1060px)`,
        isDesktop: `(min-width: 1060px)`,
      },
      handleMedia
    );

    return () => {
      matchMedia.revert();
    };
  }, []);

  const generateCards = () => {
    return props.data.map((oem, index: number) => {
      console.log("generating Cardss");
      return (
        <Card
          className={`oem-card-${index}`}
          // background={oemBgUrl.oemUrl}
          zIndex={props.data.length - index}
          key={index}
        >
          <Image
            width={1300}
            height={1400}
            // fill={true}
            // layout="fill"
            loading="lazy"
            src={oem.oemUrl}
            style={{
              objectFit: "cover",
              borderRadius: "0px 0px 24px 24px ",
              width: "auto",
              height: "59vh",
              marginLeft: "4vw",
            }}
            quality={100}
            alt={oem.oemImageAltText}
          />
        </Card>
      );
    });
  };

  const generateLogos = () => {
    return props.data.map((oem, index: number) => {
      return (
        <Logo
          src={oem.mobileLogo}
          key={index}
          zIndex={props.data.length - index}
          className={`oem-logo-${index} oem-partner-logos`}
          width={oem.logoWidthMobile}
          height={oem.logoHeightMobile}
          loading="lazy"
          margin={oem.margin}
          alt={oem.oemLogoAltText}
        />
      );
    });
  };

  return (
    <PartnersContainer className="partners-container" id={props.id}>
      <CardLogoWrapper>
        <CardContainer>{generateCards()}</CardContainer>
        <LogoContainer>{generateLogos()}</LogoContainer>
      </CardLogoWrapper>

      <TextContainer>
        {showText && (
          <LandingPageTextAnimation
            firstLine={props.firstLine}
            secondLine={props.secondLine}
            thirdLine={props.thirdLine}
            textAnimationClassName={"mobile-oem-text-animation"}
            textContainerClassName={"mobile-oem-text-container"}
            margin="0px"
            idMargin="-1vh 0vw 0vh 0vw"
          />
        )}
      </TextContainer>
      <BackgroundGlow
        background="radial-gradient(50% 50% at 50% 50%, rgba(249, 61, 102, 0.2) 0%, rgba(0, 0, 0, 0) 100%)"
        height="80vh"
        width="833px"
        bottom="10vh"
        left="0px"
        zIndex="0"
      />
    </PartnersContainer>
  );
};

import styled from "styled-components";

export const AsSimpleAsGlanceV2Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  .letter-asta {
    display: inline-block;
  }
`;

export const BackgroundImagesContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Backgroundimage = styled.div<any>`
  width: 100vw;
  min-height: 100vh;
  background: ${(props) => `url(${props.background})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const HandBackgroundImage = styled.img`
  position: absolute;
  height: 90vh;
  right: 0px;
  top: 8vh;

  /* width: 100vw; */
`;

export const ImageContainer = styled.div`
  position: absolute;
  /* height: 180px; */
  border-radius: 12px;
  right: 37.25vh;
  top: 9.5vh;
`;

export const LockscreenImage = styled.img<any>`
  position: absolute;
  width: 25.5vh;
  border-radius: 2.25vh;
  z-index: ${(props) => props.zIndex};
  opacity: 0;
`;

export const ContentSection = styled.div`
  position: absolute;
  top: 70vh;
  right: 14vh;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 60px;
    line-height: 66px;
  }
`;

export const LargeText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 46px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 60px;
    line-height: 66px;
  }
`;

export const SmallText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  letter-spacing: 0.03em;
  color: #ffffff;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 34px;
    line-height: 43px;
  }
`;
